p, span, li, label {
    color: $grey;
    font-family: $questrial;
}

a {
    color: $black;
    text-decoration: none;
}

a, p, span, li, label, h1, h2, h3, h4, h5 {
    font-display: swap;
}