$black: #000;
$coral:#ff6f69;
$grey: #b0b0b0;
$green: #6c7b24;
$purple: #4d4dff;
$pink: #FF0FF8;
$light_pink: #F9F9F7;
$dark_pink: #f008b7;
$yellow: hsl(60, 100%, 50%);
$light_yellow:#ffeead;
$turquoise: #b3ecec;
$white: white;

$neon_green: 0 0 1px #fff,
0 0 3px #fff,
0 0 5px #fff,
0 0 8px #0fa,
0 0 12px #0fa,
0 0 15px #0fa,
0 0 20px #0fa,
0 0 30px #0fa;
$neon_purple: 0 0 1px #fff,
0 0 3px #d6d6ff,
0 0 5px #c3c3ff,
0 0 8px #9b9bff,
0 0 12px #8888ff,
0 0 15px #7474ff,
0 0 20px #6161ff,
0 0 30px $purple;

$appleMusicRed: #fc3c44;
$bandcampGreen: #629aa9;
$facebookBlue: #3b5998;
$soundcloudOrange: #ff8800;
$spotifyGreen: #1DB954;
$instagramPurple: #833ab4;
$youtubeRed: #FF0000;

$errorMsgBkgColor: #fff3cd;
$errorMsgBorderColor: #ffeeba;
$errorMsgFontColor: #856404;
$successMsgFontColor: #155724;
$successMsgBorderColor: #c3e6cb;
$successMsgBkgColor: #d4edda;