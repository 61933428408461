/* The Modal (background) */
#edit-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: $white;
    margin: 10% auto 0;
    padding: 20px;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    color: $grey;
    float: right;
    font-size: 28px;
    font-weight: bold;

        &:hover,
        &:focus {
        text-decoration: none;
        cursor: pointer;
        }
  }

  ul.details {
      clear: both;

      li.link {
        word-wrap: break-word;
      }
  }