#home {
    text-align: center;

    nav {
        h2.cta {
            margin: 20px 0 40px;
    
            a {
                text-decoration: underline;
            }
        }
    }
}