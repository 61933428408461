section.shows {
    margin-top: 15px;
}

ul.details {
    clear: both;
    list-style: none;
    padding: 0;

    li {
        &:nth-of-type(2) {
            margin: 15px 0;
        }
    }

    a {
        color: $grey;
        text-decoration: underline;
        transition: $transition-color;

        &:hover {
            color: $black;
        }
    }
}

.show {
    margin: 15px auto;
}